// Меняет текст в кнопке коллапса при изменении состояния

export const initBootstrapCollapseEnhancer = () => {
  const collapseButtons = document.querySelectorAll<HTMLElement>('[data-bs-toggle="collapse"][data-bs-shown-text]')

  for (const button of collapseButtons) {
    // если есть элемент с data-bs-target, то меняем текст в нем
    const textTarget = button.querySelector('[data-collapse-text]') ?? button
    const arrowElement = button.querySelector('[data-collapse-arrow]')
    const baseText = button.dataset.bsHiddenText ?? button.textContent
    const shownText = button.dataset.bsShownText
    const collapseTarget = button.dataset.bsTarget ? document.querySelector<HTMLElement>(button.dataset.bsTarget) : null
    if (baseText && collapseTarget) {
      collapseTarget.addEventListener('hide.bs.collapse', (event) => {
        if (event.target === collapseTarget) {
          textTarget.innerHTML = baseText

          if (arrowElement) {
            arrowElement.classList.toggle('rotate-180')
          }
        }
      })

      collapseTarget.addEventListener('show.bs.collapse', (event) => {
        if (event.target === collapseTarget) {
          if (shownText) {
            textTarget.innerHTML = shownText
          }

          if (arrowElement) {
            arrowElement.classList.toggle('rotate-180')
          }
        }
      })
    }
  }
}
