<script lang="ts">
  import { addToHeaderSearchHistory } from '@js/components/HeaderSearch/headerSearchHistory.ts'
  import { type HeaderSearchResult } from '@js/components/HeaderSearch/HeaderSearchTypes.ts'
  import Loader from '@js/components/Loader.svelte'
  import SvgIcon from '@js/components/SvgIcon.svelte'
  import { debounce } from '@js/includes/functions.ts'
  import { mobile } from '@js/stores/globalStores.ts'
  import { createEventDispatcher, onMount } from 'svelte'
  import wretch from 'wretch'
  import AbortAddon from 'wretch/addons/abort'
  import QueryStringAddon from 'wretch/addons/queryString'
  import { dedupe, retry, throttlingCache } from 'wretch/middlewares'

  export let loading = false
  export let ariaControls = ''
  export let show = false
  export let id = 'search-main'
  let localClass = ''
  export { localClass as class }
  export let inputValue = ''

  let searchWrap: HTMLElement
  let resultHTML = ''
  let requestAbortController = new AbortController()
  let localWretch = wretch(import.meta.env.DEV ? '/js/__development-api/search.json' : '/ajax/search.php')
    .addon(QueryStringAddon)
    .addon(AbortAddon())
    .signal(requestAbortController)
    .middlewares([
      retry({
        maxAttempts: 3,
      }),
      dedupe(),
      throttlingCache({
        skip: (_url, options) => options.method !== 'GET',
      }),
    ])

  const dispatch = createEventDispatcher<{
    error: null
    mount: HTMLElement | null
    update: string
  }>()

  const onClick = () => {
    show = true
    if (inputValue !== '' && resultHTML === '') {
      void onInputChange()
    }
  }

  const updateAllResultsLink = (htmlString: string) => {
    const temporaryDiv = document.createElement('div')
    temporaryDiv.insertAdjacentHTML('afterbegin', htmlString)
    const searchAllLink = temporaryDiv.querySelector<HTMLLinkElement>('.search-results-modal-result--show-all a')
    if (searchAllLink) {
      searchAllLink.href = '/search/?keyword=' + encodeURIComponent(inputValue)
    }

    return temporaryDiv.innerHTML
  }

  const clearData = () => {
    inputValue = ''
    resultHTML = ''
    show = false
    loading = false
    dispatch('update', resultHTML)
  }

  // eslint-disable-next-line func-style
  async function onInputChange() {
    if (loading) {
      requestAbortController.abort()
      requestAbortController = new AbortController()
      localWretch = localWretch.signal(requestAbortController)
    }

    if (inputValue === '') {
      clearData()
      return
    }

    loading = true

    const response = await localWretch
      .query({ keyword: inputValue })
      .get()
      .onAbort(() => {})
      .json<HeaderSearchResult>()
      .catch(() => {
        dispatch('error')
        return null
      })

    if (response) {
      resultHTML = response.data
    }

    resultHTML = updateAllResultsLink(resultHTML)

    dispatch('update', resultHTML)

    // eslint-disable-next-line require-atomic-updates
    loading = false
    show = true
  }

  const formSubmit = () => {
    if (inputValue) {
      const href = '/search/?keyword=' + encodeURIComponent(inputValue)
      addToHeaderSearchHistory({
        string: inputValue,
        href,
        type: 'generic',
      })
      window.location.href = href
    }
  }

  onMount(() => {
    inputValue = window.location.search ? (new URLSearchParams(window.location.search).get('keyword') ?? '') : ''
    dispatch('mount', searchWrap)

    return () => {
      dispatch('mount', null)
    }
  })
</script>

<form class={localClass} on:submit|preventDefault={formSubmit} role="search">
  <div
    bind:this={searchWrap}
    class="search-field"
    class:search-field--active={show}
    class:search-field--mobile={$mobile}
  >
    <input
      aria-controls={ariaControls}
      autocomplete="off"
      bind:value={inputValue}
      class="form-control form-control-lg"
      {id}
      name={id}
      on:click={onClick}
      on:focusin={onClick}
      on:input={debounce(onInputChange, 200)}
      placeholder="Поиск товаров и категорий"
      type="search"
    />
    <label class="visually-hidden" for={id}>Поиск товаров и категорий</label>
    {#if loading}
      <Loader class="search-field__loader" />
    {/if}
    <button
      class="btn btn-transparent search-field__btn-reset fade"
      class:show={inputValue !== '' && !loading}
      on:click={clearData}
      type="reset"
    >
      <SvgIcon iconID="controls--close" width="10" title="Сбросить поиск" />
    </button>
    <button class="search-field__btn-submit" class:disabled={inputValue === ''} title="Искать" type="submit">
      <SvgIcon iconID="icon_search_outline" width="26" title="Искать" />
    </button>
  </div>
</form>
